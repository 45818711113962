import { BaseModel } from 'modules/core/models/base-model';

export class MatAppProposal extends BaseModel {
    id                        = null
    lot                        = null
    internal_order             = null
    entity_id                  = null
    team_id                    = null
    map_number                 = null
    map_reference              = null
    map_date                   = null
    approval_date              = null
    material_type_description  = null
    commercial_name            = null
    application_details        = null
    quantity                   = null
    use_locations              = null
    manufacturer_id            = null
    other_manufacturer         = null
    supplier_id                = null
    other_supplier             = null
    material_guarantee_period  = null
    opinion                    = null
    observations               = null
    validation_status          = null
}
