import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class MatAppProposalsRepository extends BaseRepository {
    baseUrl = 'map/mat-app-proposals';

    /**
     * Get Material Types by Other Supplier
     *
     * @param params
     * @returns {*}
     */
    allByOtherSupplier(params) {
        return this.httpClient.post(`${this.baseUrl}/all-by-other-supplier`, params);
    }

    /**
     * Get Material Types by Other Manufacturer
     *
     * @param params
     * @returns {*}
     */
    allByOtherManufacturer(params) {
        return this.httpClient.post(`${this.baseUrl}/all-by-other-manufacturer`, params);
    }

    /**
     * Get Material Type Descriptions
     *
     *
     * @returns {*}
     */
    descriptions(params) {
        return this.httpClient.post(`${this.baseUrl}/descriptions`, params);
    }

    /**
     * Get all "Other Manufacturers"
     *
     *
     * @returns {*}
     */
    otherManufacturers() {
        return this.httpClient.get(`${this.baseUrl}/other-manufacturers`);
    }

    /**
     * Get all "Other Suppliers"
     *
     *
     * @returns {*}
     */
    otherSuppliers() {
        return this.httpClient.get(`${this.baseUrl}/other-suppliers`);
    }

    /**
     * Reclassifies MAP
     *
     *
     * @returns {*}
     * @param type
     * @param data
     */
    reclassifyMAP(type, data) {
        return this.httpClient.post(`${this.baseUrl}/reclassify/${type}`, data);
    }

    /**
     * Reclassifies selected MAPs
     *
     *
     * @returns {*}
     * @param type
     * @param data
     */
    reclassifySelectedMAP(type, data) {
        return this.httpClient.post(`${this.baseUrl}/reclassify-selected/${type}`, data);
    }

    /**
     * Closes a given record
     *
     * @param id
     * @return {*}
     */
    close(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/close`);
    }

    /**
     * Reopens a given record
     *
     * @param id
     * @return {*}
     */
    reopen(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/reopen`);
    }

    /**
     * Closes a given record
     *
     * @return {*}
     * @param selectedRows
     */
    closeSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/close-selected`, { selectedRows });
    }

    /**
     * Reopens a given record
     *
     * @return {*}
     * @param selectedRows
     */
    reopenSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/reopen-selected`, { selectedRows });
    }
}
