import { inject }                     from 'aurelia-framework';
import { PLATFORM }                   from 'aurelia-pal';
import { BaseFormViewModel }          from 'base-form-view-model';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { FormSchema }                 from 'modules/map/mat-app-proposals/form-schema';
import { MatAppProposalsRepository }  from 'modules/map/mat-app-proposals/services/repository';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, MatAppProposalsRepository, FormSchema, LotInterventionsRepository)
export class CreateMatAppProposal extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'map.mat-app-proposals.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, formSchema, lotInterventionsRepository) {
        super(appContainer);

        this.repository                 = repository;
        this.formSchema                 = formSchema;
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/map/mat-app-proposals/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.mat-app-proposals.index') && super.can([
            'map.mat-app-proposals.manage',
            'map.mat-app-proposals.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.nextOrderNumber = await this.fetchNextOrderNumber();
        this.initializeModel();

        if (params.duplicatingId) {
            await this.handleDuplication(params.duplicatingId);
        }

        const lotInfo = this.appContainer.authenticatedUser.getAllSelectedLotInformation();
        this.assignLotInfo(lotInfo);

        if (params.lotInterventionId) {
            await this.assignNewLotInterventionInfo(params.lotInterventionId);
        }

        this.schema        = this.formSchema.schema(this);
        this.globalSchema  = this.formSchema.globalSchema(this);
        this.lotInfoSchema = this.formSchema.lotInfoSchema(this);
    }

    /**
     * Initializes the model and sets the initial state
     */
    initializeModel() {
        this.initialModel = this.formSchema.model(this.nextOrderNumber);
        this.model        = this.formSchema.model(this.nextOrderNumber);
    }

    /**
     * Handles the duplication of a model
     *
     * @param duplicatingId
     */
    async handleDuplication(duplicatingId) {
        const fullModel        = await this.repository.find(duplicatingId);
        const duplicatingModel = this.getDuplicatingModel(fullModel);
        this.initialModel.assign(duplicatingModel);
        this.model.assign(duplicatingModel);
    }

    /**
     * Returns the duplicating model
     *
     * @param fullModel
     * @return {{material_type_description: (null|*), quantity: (null|*), material_guarantee_period: (null|*), observations, commercial_name: (null|*), application_details: (null|*), use_locations: (null|*), team_id, categories: *, entity_id, applications: ([]|*), opinion: (null|*)}}
     */
    getDuplicatingModel(fullModel) {
        return {
            entity_id:                 fullModel.entity_id,
            team_id:                   fullModel.team_id,
            categories:                fullModel.categories,
            material_type_description: fullModel.material_type_description,
            commercial_name:           fullModel.commercial_name,
            applications:              fullModel.applications,
            application_details:       fullModel.application_details,
            quantity:                  fullModel.quantity,
            use_locations:             fullModel.use_locations,
            material_guarantee_period: fullModel.material_guarantee_period,
            observations:              fullModel.observations,
            opinion:                   fullModel.opinion,
        };
    }

    /**
     * Assigns base lot information to the model
     *
     * @param lotInfo
     */
    assignLotInfo(lotInfo) {
        const lotData = {
            lot_intervention_id:    lotInfo.lot_intervention_id,
            lot_intervention_name:  lotInfo.lot_intervention_name,
            concession_id:          lotInfo.concession_id,
            concession_name:        lotInfo.concession_name,
            highway_id:             lotInfo.highway_id,
            highway_name:           lotInfo.highway_name,
            intervention_type_id:   lotInfo.lot_intervention_type_id,
            intervention_type_name: lotInfo.lot_intervention_type_name,
        };

        this.model.assign(lotData);
    }

    /**
     Assigns the fetched lot intervention information to the model
     *
     * @param lotInterventionId
     * @return {Promise<void>}
     */
    async assignNewLotInterventionInfo(lotInterventionId) {
        const lotIntervention = await this.lotInterventionsRepository.find(lotInterventionId);

        const interventionInfo = {
            lot_intervention_id:    lotIntervention.id,
            lot_intervention_name:  lotIntervention.designation,
            concession_id:          lotIntervention.concession_id,
            concession_name:        lotIntervention.concession_name,
            highway_id:             lotIntervention.highway_id,
            highway_name:           lotIntervention.highway_name,
            intervention_type_id:   lotIntervention.lot_intervention_type_id,
            intervention_type_name: lotIntervention.lot_intervention_type_name,
        };

        this.initialModel.assign(interventionInfo);
        this.model.assign(interventionInfo);
    }

    /**
     * Fetches the next order number
     *
     * @returns {Promise}
     */
    async fetchNextOrderNumber() {
        return await this.repository.fetchNextOrderNumber();
    }

    /**
     * Handles tab click
     */
    handleTabClick() {
        if (this.create) {
            this.alert = this.warningAlertMessage(this.appContainer.i18n.tr('form.message.please-save-record-before-attaching'));
        }
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.redirectToRoute({ name: 'map.mat-app-proposals.edit', params: { id: response.model.id } }, 500);

                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
